@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family:"Noto-sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.z-index-100 {
    z-index: 100 !important;
}

.table th:first-child {
    z-index: 0 !important;
    position: static !important;
}

.table :where(tbody th, tbody td) {
    --tw-bg-opacity: 1;
    border-bottom: 1px solid darkgray;
}

.cursor-pointer {
    cursor: pointer;
}

.height-190 {
    height: 190px !important;
}

.img-center-align {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.color-gray {
    color: gray !important;
}

svg {
    overflow: visible;
}
